import { ReactElement } from 'react';
import { getProductUrlPath } from '@srnade/web/utils';
import { ProductFormatTileType, ProductTile } from '../Tiles/ProductTile/ProductTile.component';

type ProductFormatType = ProductFormatTileType & { id: string; slug: string };

export type ProductFormatsProps = {
    productFormats: ProductFormatType[];
};

// Array of ProductTiles. Note that this component can't be used as a direct child of a
// Carousel component, as it's not treated as an array of children. In this case you must
// map the array of ProductTiles explictly.
//
export const ProductFormats = (props: ProductFormatsProps): ReactElement[] => {
    return props.productFormats.map((productFormat) => (
        <ProductTile
            key={productFormat.id}
            productFormat={productFormat}
            hoverable
            clickable
            url={getProductUrlPath(productFormat.artist.username, productFormat.slug)}
        />
    ));
};
