import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Typography } from '@srnade/component-ui';
import { Container, LinkWrapper } from '@srnade/web/components';
import { useTranslation } from '@srnade/web/i18n/client';
import clsx from 'clsx';
import { ProductFormats } from '../ProductFormats/ProductFormats.component';
import { SkeletonProductList } from '../SkeletonProductTile/SkeletonProductList.component';
import {
    GtmPageNames,
    GtmProductEventType,
    GtmPromoNames,
    GtmPromoOrder,
    GtmPromotion,
    GtmPromotionEventType,
} from '@srnade/web/types';
import { GtmEvents, promoId, slot } from '@srnade/web/utils';
import { useGtmContext } from '@srnade/web/store/context/gtm.context';
import { useAppDispatch, useAppSelector } from '@srnade/web/store/global';
import {
    clearProductFormats,
    fetchProductFormats,
    ProductFormatsStatus,
} from '@srnade/web/store/reducers/product-formats';
import { ProductFormatOrderBy, ProductStatusNew } from '@srnade/web/__generated__/graphql';
import * as utils from '../../utils';

const HEADLINE_PAGE_SIZE = 4;

export const HeadlineProducts = () => {
    const { t } = useTranslation('homepage');
    const dispatch = useAppDispatch();
    const error = useAppSelector((state) => state.products.error['headline']);
    const formats = useAppSelector((state) => state.products.productFormats['headline']);
    const loadingStatus = useAppSelector((state) => state.products.status['headline']);
    const [pageSize, setPageSize] = useState(HEADLINE_PAGE_SIZE);

    const title = t('headline.title');
    const { startPromo } = useGtmContext();

    useEffect(() => {
        if (loadingStatus !== ProductFormatsStatus.Idle) {
            setPageSize(HEADLINE_PAGE_SIZE);
            dispatch(clearProductFormats({ key: 'headline' }));
        }
        dispatch(
            fetchProductFormats({
                variables: {
                    first: HEADLINE_PAGE_SIZE,
                    filters: {
                        isListed: true,
                        productStatus: [ProductStatusNew.RELEASED, ProductStatusNew.PRE_ORDER],
                    },
                    orderBy: [ProductFormatOrderBy.RELEASE_OR_PREORDER_START_DATE_DESC],
                },
                key: 'headline',
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startHeadlinePromo: GtmPromotion = useMemo(() => {
        return {
            creative_name: GtmPromoNames.CREATIVE_HEADLINE,
            creative_slot: slot(GtmPromoOrder.HeadlineShopAll),
            promotion_id: promoId(GtmPromoOrder.HeadlineShopAll),
            promotion_name: GtmPromoNames.PROMO_HEADLINE,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GtmPromoNames]);

    const gtmHeadlineEvents = useRef(
        new GtmEvents(
            GtmPageNames.PAGE_HEADLINE_RELEASES,
            [GtmProductEventType, GtmPromotionEventType],
            startHeadlinePromo,
        ),
    );

    const handleShopAllClick = () => {
        // Only start a promo once Shop All is clicked
        const gtmEvents = gtmHeadlineEvents.current;
        gtmEvents.promoMap(() => {
            if (gtmEvents.promoContext) {
                startPromo(gtmEvents.promoContext);
            }
        });
    };

    const handleClickShowMore = () => {
        const newPageSize = HEADLINE_PAGE_SIZE;
        setPageSize(newPageSize);
        dispatch(
            fetchProductFormats({
                variables: {
                    first: newPageSize,
                    after: formats?.pageInfo.endCursor,
                    filters: {
                        isListed: true,
                        productStatus: [ProductStatusNew.RELEASED, ProductStatusNew.PRE_ORDER],
                    },
                    orderBy: [ProductFormatOrderBy.RELEASE_OR_PREORDER_START_DATE_DESC],
                },
                key: 'headline',
            }),
        );
    };

    if (!!error || loadingStatus === ProductFormatsStatus.Failed) {
        return null;
    }

    return (
        <Container className="mt-[6rem]" gtmId="home_headline">
            <div className="tablet:flex">
                <Typography variant="h1">{title}</Typography>
                <div className="flex ml-auto mt-[3rem] tablet:mt-0 tablet:items-center">
                    <LinkWrapper href={`/shop-all`} handleClick={() => handleShopAllClick()}>
                        <Button type="text" text={t('shopAllButton')} />
                    </LinkWrapper>
                </div>
            </div>
            <div
                className={clsx(
                    'grid px-0 mx-auto max-w-[45rem] gap-y-[5rem] tablet:mx-0 tablet:max-w-full tablet:flex tablet:flex-wrap tablet:justify-between laptop:grid laptop:grid-cols-4 laptop:gap-x-[1.6rem] desktop:gap-x-[2rem] mt-[3rem]',
                )}
                gtm-id="home_headline"
                data-testid="product-formats"
            >
                {loadingStatus === ProductFormatsStatus.Pending && !formats ? (
                    <SkeletonProductList size={4} />
                ) : (
                    <ProductFormats productFormats={utils.extractNodes(formats)} />
                )}
            </div>
            <div className="flex justify-center mt-[5rem]">
                <Button
                    className="w-[33.5rem] tablet:w-[29.5rem]"
                    type="standard"
                    setIcon={false}
                    text={t('headline.loadMoreButton')}
                    onClick={handleClickShowMore}
                    loading={loadingStatus === ProductFormatsStatus.Pending}
                />
            </div>
        </Container>
    );
};
