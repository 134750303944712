import React, { useMemo, useRef } from 'react';
import type { NextPage } from 'next';
import { useTranslation } from '@srnade/web/i18n/client';

import { PageTemplate } from '@srnade/web/templates';
import {
    PartnersBanner,
    TopAccounts,
    USPBanner,
    EducationalModule,
    HeroProducts,
} from '@srnade/web/components';
import {
    GtmPageNames,
    GtmProductEventType,
    GtmPromoNames,
    GtmPromoOrder,
    GtmPromotion,
    GtmPromotionEventType,
} from '@srnade/web/types';
import { GtmEvents, promoId, slot } from '@srnade/web/utils';
import { HeadlineProducts } from '../components/HeadlineProducts';

const Home: NextPage = () => {
    const { t } = useTranslation('homepage');

    const startPreviousPromo: GtmPromotion = useMemo(() => {
        return {
            creative_name: GtmPromoNames.CREATIVE_PREVIOUS,
            creative_slot: slot(GtmPromoOrder.PreviousSeeAll),
            promotion_id: promoId(GtmPromoOrder.PreviousSeeAll),
            promotion_name: GtmPromoNames.PROMO_PREVIOUS,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GtmPromoNames]);

    const gtmPreviousEvents = useRef(
        new GtmEvents(
            GtmPageNames.PAGE_PREVIOUS_RELEASES,
            [GtmProductEventType, GtmPromotionEventType],
            startPreviousPromo,
        ),
    );

    return (
        <PageTemplate title={t('title')} description={t('description')} showSubscribeBanner showFaqs>
            <HeroProducts />

            <div className="mt-[6rem]">
                <USPBanner />
            </div>
            <HeadlineProducts />

            <div className="mt-[6rem] mb-[1rem]">
                <EducationalModule />
            </div>
            <div className="mt-[6rem]">
                <TopAccounts />
            </div>
            <div className="mt-[10rem]">
                <PartnersBanner />
            </div>
        </PageTemplate>
    );
};

export default Home;
